<template>
  <b-form class="content-form">
    <b-form-checkbox
      v-model="content.active"
      name="active"
      class="content-switch"
      switch
      ><b>{{ $t("active") }}</b></b-form-checkbox
    >
    <div>
      <h2>Chat</h2>
      <b-form-checkbox
        v-model="content.chat.active"
        name="active"
        class="content-switch"
        switch
        ><b>{{ $t("active") }}</b></b-form-checkbox
      >

      <b-tabs class="mt-3">
        <b-tab v-for="language in languages" :key="language">
          <template #title>
            <country-flag :country="language" size="normal" />
          </template>
          <b-form-group label="Type">
            <b-form-select
              v-model="content.chat.type[language]"
              :options="chatTypes"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            class="mb-2 mr-sm-2 mb-sm-0"
            label="Chat embed"
            v-if="content.chat.type[language] !== 'online-users'"
          >
            <b-input-group
              :prepend="ChatURLPrefix(content.chat.type[language])"
            >
              <b-form-input v-model="content.chat.id[language]"></b-form-input>
              <!-- <a>Where can I find this?</a> -->
            </b-input-group>
          </b-form-group>
        </b-tab>
      </b-tabs>
      <h2 class="mt-3">Social</h2>
      <b-form-checkbox
        v-model="content.social.active"
        name="active"
        class="content-switch"
        switch
        ><b>{{ $t("active") }}</b></b-form-checkbox
      >
      <h2 class="mt-3">Online users</h2>
      <b-form-checkbox
        v-model="content.onlineusers.active"
        name="active"
        class="content-switch"
        switch
        ><b>{{ $t("active") }}</b></b-form-checkbox
      >
    </div>
  </b-form>
</template>
<script>
export default {
  props: ["content", "settings"],
  data() {
    return {
      chatTypes: [
        { value: "slido", text: "Slido" },
        { value: "vimeo", text: "VimeoChat" },
        { value: "embedded-chat", text: "EmbeddedChat" },
      ],
    };
  },
  methods: {
    ChatURLPrefix(type) {
      let url;
      switch (type) {
        case "vimeo":
          url = "https://vimeo.com/event/";
          break;
        case "slido":
          url = "https://app.sli.do/event/";
          break;
        case "embedded-chat":
          url = null;
          break;
        default:
          url = "No livestream platform specified";
      }
      return url;
    },
  },
  computed: {
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>
